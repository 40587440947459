import React, { useContext } from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { Link, navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import store from 'store';
import { StringParam, useQueryParam } from 'use-query-params';

import { GlobalContext } from '../context/GlobalContextProvider';

const strings = new LocalizedStrings({
    en: {
        clients: 'Clients',
        events: 'Events',
        operators: 'Operators',
        logout: 'Logout',
    },
    de: {
        clients: 'Kunden',
        events: 'Events',
        operators: 'Moderatoren',
        logout: 'Logut',
    },
});

export const Menu = () => {
    const user: Users.User = store.get('user');
    const [clientId, setClientId] = useQueryParam('clientId', StringParam);
    const context = useContext(GlobalContext);

    return (
        <AntdMenu theme="dark" mode="horizontal" defaultSelectedKeys={['0']}>
            <AntdMenu.Item key="1" onClick={() => navigate('/')}>
                {strings.clients}
            </AntdMenu.Item>

            {user?.roles?.includes('super-admin') && clientId && (
                <>
                    <AntdMenu.Item key="3" onClick={() => navigate('/operator-list?clientId=' + clientId)}>
                        {strings.operators}
                    </AntdMenu.Item>
                </>
            )}

            <>
                {clientId && (
                    <AntdMenu.Item
                        key="2"
                        onClick={() => {
                            context.setActiveEvent(undefined);
                            navigate('/event-list?clientId=' + clientId);
                        }}
                    >
                        {strings.events}
                    </AntdMenu.Item>
                )}
            </>

            {clientId && user?.roles?.includes('admin') && (
                <>
                    <AntdMenu.Item key="3" onClick={() => navigate('/operator-list?clientId=' + clientId)}>
                        {strings.operators}
                    </AntdMenu.Item>
                </>
            )}

            <AntdMenu.Item key="4">
                <Link
                    to="/login"
                    onClick={() => {
                        context.unsubscribeAllFromNotifier();
                        store.remove('user');
                        store.remove('token');
                    }}
                >
                    <LogoutOutlined />
                    {strings.logout}
                </Link>
            </AntdMenu.Item>
        </AntdMenu>
    );
};

export default Menu;
